<template>
  <Layout>
    <page-layout page-titles="役職管理" :bread-crumb-items="items">
      <template #table-content>
        <management-job-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
          :show-delete-dialog="showDeleteDialog"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <management-job-modal-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
            <delete-confirmation :id="dialogDeleteId" :confirm-delete-func="onDeleteConfirm" />
          </template>
        </management-job-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import ManagementJobTable from '@/components/tables/management-job-table'
import ManagementJobModalForm from '@/components/forms/management-job-modal-form'
import DeleteConfirmation from '@/components/modals/delete-confirmation-modal'
import { mapActions } from 'vuex'
import pick from 'lodash-es/pick'
import { ALERT_TYPE } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { managementJobData } from '@/services/management-job-service'

export default {
  name: 'ManagementJob',
  components: {
    DeleteConfirmation,
    ManagementJobModalForm,
    ManagementJobTable,
    Layout,
    PageLayout,
    CModal,
  },
  data() {
    return {
      searchInput: '',
      formData: null,
      dialogId: 'mj-dialog',
      dialogDeleteId: 'del-dialog',
      dialogUpdateId: 'update-dialog',
      dialogTitle: '',
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: ['code', 'name'],
      requiredEditKeys: ['id', 'code', 'name'],
      onAddOrEditConfirm: () => {
        return {}
      },
      items: [
        {
          text: '部署・従業員管理',
          href: '/management-member',
        },
        {
          text: '役職',
          active: true,
        },
      ],
      dialogTitles: {
        ADD: {
          id: 1,
          text: '役職新規作成',
        },
        EDIT: {
          id: 2,
          text: '役職情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
        buttonText: '',
      },
    }
  },
  computed: {
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: this.buttonText,
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    showEditDialog(rowData, editSuccessCallback) {
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.formData = { ...rowData }
      this.buttonText = '更新'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showAddDialog(createSuccessCallback) {
      this.dialogTitle = this.dialogTitles.ADD.text
      this.formData = null
      this.buttonText = '保存'
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            const { success, data } = await managementJobData.createItem(formData)
            if (!success) {
              this.showAlertError(data)
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.$bvModal.show(this.dialogId)
    },

    showDeleteDialog(deleteId, deleteSuccessCallback) {
      this.deleteId = deleteId
      this.callbackFn = deleteSuccessCallback
      this.dialogTitle = this.dialogTitles.DELETE.text
      this.$bvModal.show(this.dialogDeleteId)
    },

    async onDeleteConfirm() {
      const result = await managementJobData.deleteItem(this.deleteId)
      if (result) {
        // delete success
        this.callbackFn && (await this.callbackFn())
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG05,
        })
      }
      this.$bvModal.hide(this.dialogDeleteId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await managementJobData.editItem(formData)
      if (!success) {
        this.showAlertError(data)
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    getErrorMessage(messages) {
      const result = []
      if (messages.code) {
        result.push(MESSAGES.CUSTOMER_MT.KH06)
      }
      if (messages.name) {
        result.push(MESSAGES.CUSTOMER_MT.KH07)
      }
      return result
    },
    showAlertError(messages) {
      const errorMessages = this.getErrorMessage(messages)
      errorMessages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
  },
}
</script>
