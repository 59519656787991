<template>
  <div class="CMForm">
    <b-form>
      <b-form-group id="input-group-1" label="役職ID" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            v-model="form.code"
            type="text"
            :class="status($v.form.code)"
            :formatter="formatter"
            @change="setFormValue('code', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.code" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.code" class="label-error"
          >{{ errorMessages.form.code }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-2" label="役職名" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            v-model="form.name"
            type="text"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { MESSAGES } from '@/utils/messages'
export default {
  name: 'ManagementJobModalForm',
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      errorMessages: {
        form: {
          name: '',
          code: '',
        },
      },
      options: [],
      maxLength: 255,
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      code: {
        required,
      },
    },
  },
  computed: {
    defaultFormData() {
      return { id: '', code: '', name: '' }
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.formData }
    }
  },
  methods: {
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
      } else {
        this.errorMessages.form[name] = ''
      }
    },
    setFormValue(name, value) {
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
    formatter(e) {
      return String(e).substring(0, this.maxLength)
    },
  },
}
</script>
